import axios from 'axios';

// Create an instance of axios
export const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL + '/api/' : '/api/',
    withCredentials: true,
});

// Log requests
api.interceptors.request.use(request => {
    console.log('Starting Request', request.method, request.url);
    return request;
});

// Log responses
api.interceptors.response.use(response => {
    console.log('Response:', response.status, response.config.url);
    return response;
});

export const refreshToken = async () => {
    try {
        const refreshToken = localStorage.getItem('refresh_token');
        if (!refreshToken) {
            throw new Error('No refresh token available');
        }
        const response = await api.post('auth/token/refresh/', { refresh: refreshToken });
        localStorage.setItem('access_token', response.data.access);
        await fetchCSRFToken();
        return response.data.access;
    } catch (error) {
        console.error('Error refreshing token:', error);
        throw error;
    }
};

export const fetchCSRFToken = async () => {
    try {
        await api.get('auth/csrf/');
        console.log('CSRF token fetched');
    } catch (error) {
        console.error('Error fetching CSRF token:', error);
    }
};

// Add a request interceptor
api.interceptors.request.use((config) => {
    // Get the JWT token from localStorage
    const token = localStorage.getItem('access_token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    if (config.method !== 'get') {
        // Get the CSRF token from the cookie
        // console.log('All cookies:', document.cookie);
        const csrfToken = document.cookie.split('; ')
            .find(row => row.startsWith('csrftoken='))
            ?.split('=')[1];

        if (csrfToken) {
            config.headers['X-CSRFToken'] = csrfToken;
            // console.log('CSRF token found and set:', csrfToken);
        } else {
            console.warn('CSRF token not found in cookies');
            console.log('All cookies:', document.cookie);
        }
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

// Add a response interceptor to handle CSRF token errors
api.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const access_token = await refreshToken();
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
                return api(originalRequest);
            } catch (refreshError) {
                console.error('Token refresh failed:', refreshError);
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);

export default api;
