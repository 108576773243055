import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';
import cookieManager from '../utils/cookieManager';

const CookieConsentBanner = () => {
    const handleAcceptCookie = () => {
        cookieManager.setConsent(true);
        // Initialize analytics or other tracking here
    };

    const handleDeclineCookie = () => {
        cookieManager.setConsent(false);
        // Disable analytics or other tracking here
    };

    return (
        <CookieConsent
            location="bottom"
            buttonText="Accept"
            declineButtonText="Decline"
            enableDeclineButton
            onAccept={handleAcceptCookie}
            onDecline={handleDeclineCookie}
            style={{ background: "#2B373B" }}
            buttonStyle={{
                background: "#4CAF50",
                color: "white",
                fontSize: "13px",
                borderRadius: "3px",
                padding: "5px 20px"
            }}
            declineButtonStyle={{
                background: "#f44336",
                color: "white",
                fontSize: "13px",
                borderRadius: "3px",
                padding: "5px 20px"
            }}
            expires={365}
        >
            This website uses cookies to enhance the user experience. See our{" "}
            <Link
                to="/privacy-policy"
                style={{
                    color: "#FFF",
                    textDecoration: "underline",
                    marginLeft: "5px"
                }}
            >
                privacy policy
            </Link>
        </CookieConsent>
    );
};

export default CookieConsentBanner;
