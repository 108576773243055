import { api, fetchCSRFToken } from './apiService';

function getDeviceInfo() {
    const userAgent = navigator.userAgent;
    const browserName = (() => {
        if (userAgent.match(/chrome|chromium|crios/i)) return "Chrome";
        if (userAgent.match(/firefox|fxios/i)) return "Firefox";
        if (userAgent.match(/safari/i)) return "Safari";
        if (userAgent.match(/opr\//i)) return "Opera";
        if (userAgent.match(/edg/i)) return "Edge";
        return "Unknown";
    })();

    return {
        deviceName: navigator.platform || 'Unknown Device',
        browserName: browserName
    };
}

export const registerWebPush = async (subscription) => {
    try {
        console.log('Registering web push:', subscription);
        await fetchCSRFToken();

        const deviceInfo = getDeviceInfo();
        console.log('Device info:', deviceInfo);

        const response = await api.post('register-web-push/', {
            subscription,
            deviceInfo
        });
        console.log('Web push registered:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error registering web push:', error);
        throw error;
    }
};

export const isPushNotificationSupported = () => {
    return 'serviceWorker' in navigator && 'PushManager' in window;
};

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export const subscribeUser = async () => {
    if (!isPushNotificationSupported()) {
        console.log('Push notifications are not supported in this browser');
        return false;
    }

    try {
        const permission = await Notification.requestPermission();
        console.log('Notification permission:', permission);

        if (permission !== 'granted') {
            console.log('Notification permission denied');
            return false;
        }

        const registration = await navigator.serviceWorker.ready;

        // Check for existing subscription
        let subscription = await registration.pushManager.getSubscription();
        if (subscription) {
            console.log('Using existing subscription');
            await registerWebPush(subscription);
            return true;
        }

        // Create new subscription
        console.log('Creating new push subscription...');
        const vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;
        if (!vapidPublicKey) {
            throw new Error('VAPID public key not configured');
        }

        subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(vapidPublicKey)
        });

        console.log('New subscription created:', subscription);
        await registerWebPush(subscription);
        return true;
    } catch (error) {
        console.error('Failed to register web push: ', error);
        return false;
    }
};

export const getNotificationPermissionStatus = () => {
    if (!isPushNotificationSupported()) {
        return 'unsupported';
    }
    return Notification.permission; // Returns 'granted', 'denied', or 'default'
};

export const requestNotificationPermission = async () => {
    try {
        // Check current permission
        const currentPermission = Notification.permission;

        if (currentPermission === 'denied') {
            // Can't request again, need to use browser settings
            return false;
        }

        // This will show the original browser prompt if permission is 'default'
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            return await subscribeUser();
        }
        return false;
    } catch (error) {
        console.error('Error requesting notification permission:', error);
        return false;
    }
};

export const unsubscribeFromNotifications = async () => {
    try {
        console.log('Starting unsubscribe process...');
        let subscription = null;
        const deviceInfo = getDeviceInfo();

        // Try to get and unsubscribe from push manager if available
        if ('serviceWorker' in navigator) {
            const registration = await navigator.serviceWorker.ready;
            subscription = await registration.pushManager.getSubscription();

            if (subscription) {
                console.log('Found existing subscription:', subscription.endpoint);
                await subscription.unsubscribe();
                console.log('Unsubscribed from push manager');
            }
        }

        // Always call backend to clean up registrations
        await fetchCSRFToken();
        const response = await api.post('unregister-web-push/', {
            deviceInfo,
            all: !subscription
        });
        console.log('Backend response:', response.data);
        return true;

    } catch (error) {
        console.error('Error unsubscribing from notifications:', error);
        return false;
    }
};

// Add this new function to test notifications
export const testNotification = async () => {
    try {
        await fetchCSRFToken();
        const response = await api.post('test-web-push/');
        console.log('Test notification response:', response.data);
        return true;
    } catch (error) {
        console.error('Error testing notification:', error);
        return false;
    }
};
