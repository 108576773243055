import React from 'react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
    return (
        <div className="bg-base-200 min-h-screen">
            <main className="container mx-auto px-4 py-16">
                <section className="text-center mb-16">
                    <h2 className="text-4xl font-bold mb-4">Supercharge Your Web Development</h2>
                    <p className="text-xl text-base-content/70 mb-8">The ultimate Django-React boilerplate with everything you need to build, deploy, and monetize your web app at lightning speed.</p>
                    <Link
                        to="/pricing"
                        className="btn btn-primary btn-lg"
                    >
                        Start Building Now
                    </Link>
                </section>

                <section className="grid md:grid-cols-3 gap-8 mb-16">
                    <FeatureCard
                        title="Full-Stack Power"
                        description="Django backend with PostgreSQL, decoupled React frontend, and REST APIs for seamless integration."
                    />
                    <FeatureCard
                        title="Authentication & Payments"
                        description="JWT authentication, social logins, and Stripe integration for subscriptions and one-time payments."
                    />
                    <FeatureCard
                        title="Modern UI & UX"
                        description="daisyUI + Tailwind CSS for stunning designs, custom fonts, and PWA support for installations and notifications."
                    />
                    <FeatureCard
                        title="Scalable Architecture"
                        description="Redis caching, Celery for background tasks, and AWS S3 for media storage to handle growth with ease."
                    />
                    <FeatureCard
                        title="Deployment Ready"
                        description="One-click deployment to Railway or Render, with environmental variable configuration for flexibility."
                    />
                    <FeatureCard
                        title="Developer Friendly"
                        description="Custom user model, email service integration, and easily swappable frontend framework for maximum flexibility."
                    />
                </section>

                <section className="text-center mb-16">
                    <h3 className="text-3xl font-bold mb-4">Why Choose django-shipfast?</h3>
                    <ul className="text-left max-w-2xl mx-auto">
                        <ListItem text="Save weeks of setup time with our pre-configured stack" />
                        <ListItem text="Focus on building your unique features, not boilerplate code" />
                        <ListItem text="Scale confidently with battle-tested technologies" />
                        <ListItem text="Monetize quickly with built-in Stripe integration" />
                        <ListItem text="Deploy effortlessly to popular platforms" />
                    </ul>
                </section>

                <section className="text-center">
                    <h3 className="text-2xl font-bold mb-4">Ready to ship your new web app?</h3>
                    <Link
                        to="/pricing"
                        className="btn btn-primary btn-lg"
                    >
                        Get Started Free
                    </Link>
                </section>
            </main>
        </div>
    );
};

const FeatureCard = ({ title, description }) => {
    return (
        <div className="bg-base-100 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2">{title}</h3>
            <p className="text-base-content/70">{description}</p>
        </div>
    );
};

const ListItem = ({ text }) => {
    return (
        <li className="flex items-center mb-2">
            <svg className="w-6 h-6 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
            </svg>
            {text}
        </li>
    );
};

export default LandingPage;
