import { api, fetchCSRFToken } from './apiService';

export const checkout = async (priceId) => {
  try {
    await fetchCSRFToken();
    const response = await api.post('checkout/', {
      price_id: priceId
    });
    console.log('Checkout session created:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating checkout session:', error.response?.data || error.message);
    throw error;
  }
};

export const getProducts = async () => {
  try {
    const response = await api.get('products/');
    console.log('Products:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error.response?.data || error.message);
    throw error;
  }
};

export const unsubscribe = async (subscriptionId) => {
  try {
    await fetchCSRFToken();
    const response = await api.post('unsubscribe/', {
      subscription_id: subscriptionId
    });
    console.log('Subscription cancelled:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error cancelling subscription:', error.response?.data || error.message);
    throw error;
  }
};

export const getUserSubscriptions = async () => {
  try {
    const response = await api.get('user/subscriptions/');
    console.log('User subscriptions:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching subscriptions:', error.response?.data || error.message);
    throw error;
  }
};

export const getUserOneTimePurchases = async () => {
  try {
    const response = await api.get('user/purchases/');
    console.log('User purchases:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching purchases:', error.response?.data || error.message);
    throw error;
  }
};

export const getUserAllPurchases = async () => {
  try {
    const response = await api.get('user/billing/');
    console.log('Billing history:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching billing history:', error.response?.data || error.message);
    throw error;
  }
};
