import React from 'react';
import Logo from './Logo';
import InstallPrompt from './InstallPrompt';

const Footer = () => {
    return (
        <footer className="footer bg-neutral text-neutral-content p-4">
            <div className="flex justify-between items-center w-full">
                <aside className="flex items-center gap-2">
                    <Logo />
                    <p>Copyright © {new Date().getFullYear()} - All rights reserved</p>
                </aside>
                <nav>
                    <InstallPrompt />
                </nav>
            </div>
        </footer>
    );
};

export default Footer;
