import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { changePassword } from '../client/authService';

const PasswordChange = () => {
    const [formData, setFormData] = useState({
        old_password: '',
        new_password1: '',
        new_password2: '',
    });
    const [err, setError] = useState(null);
    const navigate = useNavigate();

    const [showPasswords, setShowPasswords] = useState(false);

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        // Basic validation
        if (formData.new_password1 !== formData.new_password2) {
            setError('New passwords do not match.');
            return;
        }

        if (formData.new_password1.length < 8) {
            setError('New password must be at least 8 characters long.');
            return;
        }

        if (formData.new_password1 === formData.old_password) {
            setError('New password must be different from your current password.');
            return;
        }

        try {
            await changePassword(formData);
            navigate('/profile', { state: { message: 'Password changed successfully' } });
        } catch (err) {
            console.error('Error changing password:', err);
            if (err.response && err.response.data) {
                const errorMessages = Object.values(err.response.data).flat();
                setError(
                    <ul className="list-disc list-inside">
                        {errorMessages.map((message, index) => (
                            <li key={index}>{message}</li>
                        ))}
                    </ul>
                );
            } else {
                setError('An error occurred while changing password.');
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPasswords(prev => !prev);
    };

    return (
        <>
            <Helmet>
                <title>Change Password | {process.env.REACT_APP_SITE_NAME}</title>
                <meta name="description" content="Change your account password securely." />
                <meta name="keywords" content="password change, account security, update password" />
                <link rel="canonical" href={`https://${process.env.REACT_APP_SITE_DOMAIN}/password-change`} />

                {/* Open Graph tags */}
                <meta property="og:title" content={`Change Password | ${process.env.REACT_APP_SITE_NAME}`} />
                <meta property="og:description" content="Change your account password securely." />
                <meta property="og:url" content={`https://${process.env.REACT_APP_SITE_DOMAIN}/password-change`} />
            </Helmet>
            <div className="container mx-auto mt-8 p-4 max-w-md">
                <div className="card bg-base-100 shadow-xl">
                    <div className="card-body">
                        <h2 className="card-title text-2xl font-bold mb-4">Change Password</h2>
                        {err && <div className="alert alert-error">{err}</div>}
                        <form onSubmit={handleSubmit}>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Current Password:</span>
                                </label>
                                <label className="input input-bordered flex items-center gap-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70">
                                        <path fillRule="evenodd" d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z" clipRule="evenodd" />
                                    </svg>
                                    <input
                                        type={showPasswords ? "text" : "password"}
                                        name="old_password"
                                        className="grow"
                                        value={formData.old_password}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <button
                                        type="button"
                                        onClick={togglePasswordVisibility}
                                        className="btn btn-ghost btn-circle btn-sm"
                                    >
                                        {showPasswords ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                            </svg>
                                        )}
                                    </button>
                                </label>
                            </div>

                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">New Password:</span>
                                </label>
                                <label className="input input-bordered flex items-center gap-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70">
                                        <path fillRule="evenodd" d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z" clipRule="evenodd" />
                                    </svg>
                                    <input
                                        type={showPasswords ? "text" : "password"}
                                        name="new_password1"
                                        className="grow"
                                        value={formData.new_password1}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </label>
                            </div>

                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Confirm New Password:</span>
                                </label>
                                <label className="input input-bordered flex items-center gap-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70">
                                        <path fillRule="evenodd" d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z" clipRule="evenodd" />
                                    </svg>
                                    <input
                                        type={showPasswords ? "text" : "password"}
                                        name="new_password2"
                                        className="grow"
                                        value={formData.new_password2}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </label>
                            </div>

                            <div className="card-actions justify-end mt-4">
                                <button type="submit" className="btn btn-primary">Change Password</button>
                                <button type="button" onClick={() => navigate('/profile')} className="btn btn-ghost">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PasswordChange;
