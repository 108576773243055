import React from 'react';
import { Helmet } from 'react-helmet-async';
import LandingPage from '../components/LandingPage';

const Home = () => {
    return (
        <>
            <Helmet>
                <title>{`${process.env.REACT_APP_SITE_NAME}`}</title>
                <meta name="description" content="Welcome to our platform. Discover how we can help you streamline your workflow and boost productivity." />
                <meta name="keywords" content="home, landing page, platform, productivity" />
                <link rel="canonical" href={`https://${process.env.REACT_APP_SITE_DOMAIN}`} />

                {/* Open Graph tags */}
                <meta property="og:title" content={`Home | ${process.env.REACT_APP_SITE_NAME}`} />
                <meta property="og:description" content="Welcome to our platform. Discover how we can help you streamline your workflow and boost productivity." />
                <meta property="og:url" content={`https://${process.env.REACT_APP_SITE_DOMAIN}`} />
            </Helmet>
            <main>
                <LandingPage />
            </main>
        </>
    );
};

export default Home;
