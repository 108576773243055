import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { fetchCSRFToken } from './client/apiService';
import TopNavbar from './components/TopNavbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Profile from './pages/Profile';
import PasswordResetRequest from './pages/PasswordResetRequest';
import PasswordResetConfirm from './pages/PasswordResetConfirm';
import PasswordChange from './pages/PasswordChange';
import Pricing from './pages/Pricing';
import Payment from './pages/Payment';
import PaymentResult from './pages/PaymentResult';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { HelmetProvider } from 'react-helmet-async';
import CookieConsentBanner from './components/CookieConsentBanner';
import { unsubscribeFromNotifications, requestNotificationPermission } from './client/notificationService';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const DevelopmentNotice = () => {
    return (
        <div className="alert alert-warning shadow-lg max-w-4xl mx-auto mt-4">
            <ExclamationTriangleIcon className="stroke-current shrink-0 h-6 w-6" />
            <div>
                <h3 className="font-bold">Development Notice</h3>
                <div className="text-sm">This site is under active development and testing. Products are not available for purchase at this time. Stay tuned for updates!</div>
            </div>
        </div>
    );
};

function App() {
    useEffect(() => {
        fetchCSRFToken();

        const handleServiceWorkerMessage = async (event) => {
            console.log('Received service worker message:', event.data);

            if (event.data.type === 'SUBSCRIPTION_CHANGED') {
                console.log('Subscription change detected, status:', event.data.status);

                if (event.data.status === 'removed') {
                    console.log('Handling unsubscribe...');
                    try {
                        await unsubscribeFromNotifications();
                        console.log('Successfully unsubscribed');
                    } catch (error) {
                        console.error('Error unsubscribing:', error);
                    }
                } else if (event.data.status === 'renewed') {
                    console.log('Handling resubscribe...');
                    try {
                        await requestNotificationPermission();
                        console.log('Successfully resubscribed');
                    } catch (error) {
                        console.error('Error resubscribing:', error);
                    }
                }
            }
        };

        // Listen for service worker messages
        if ('serviceWorker' in navigator) {
            console.log('Setting up service worker message listener');
            navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);
        } else {
            console.log('Service worker not supported');
        }

        // Permission change monitoring
        if ('permissions' in navigator) {
            navigator.permissions.query({ name: 'notifications' })
                .then(permissionStatus => {
                    console.log('Current notification permission:', permissionStatus.state);

                    permissionStatus.addEventListener('change', async () => {
                        const newState = permissionStatus.state;
                        console.log('Notification permission changed to:', newState);

                        if (newState === 'granted') {
                            // Resubscribe when permission is granted
                            try {
                                await requestNotificationPermission();
                                console.log('Successfully subscribed after permission grant');
                            } catch (error) {
                                console.error('Error subscribing:', error);
                            }
                        } else if (newState === 'denied') {
                            // Unsubscribe when permission is denied
                            try {
                                await unsubscribeFromNotifications();
                                console.log('Successfully unsubscribed after permission denial');
                            } catch (error) {
                                console.error('Error unsubscribing:', error);
                            }
                        }
                    });
                });
        }

        return () => {
            if ('serviceWorker' in navigator) {
                console.log('Cleaning up service worker message listener');
                navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
            }
        };
    }, []);

    return (
        <HelmetProvider>
            <AuthProvider>
                <Router>
                    <div className="App min-h-screen bg-base-100 flex flex-col" data-theme="bumblebee">
                        <TopNavbar />
                        <DevelopmentNotice />
                        <div className="flex-grow">
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/login" element={<Login />} />
                                <Route path="/signup" element={<Signup />} />
                                <Route path="/profile" element={<Profile />} />
                                <Route path="/password-reset" element={<PasswordResetRequest />} />
                                <Route path="/password-reset/confirm/:uid/:token/" element={<PasswordResetConfirm />} />
                                <Route path="/password-change" element={<PasswordChange />} />
                                <Route path="/pricing" element={<Pricing />} />
                                <Route path="/payment" element={<Payment />} />
                                <Route path="/payment-result" element={<PaymentResult />} />
                                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                            </Routes>
                        </div>
                        <Footer />
                        <CookieConsentBanner />
                    </div>
                </Router>
            </AuthProvider>
        </HelmetProvider>
    );
}

export default App;
