import Cookies from 'js-cookie';

export const COOKIE_CONSENT_KEY = 'cookieConsent';

export const cookieManager = {
    hasConsent: () => {
        return Cookies.get(COOKIE_CONSENT_KEY) === 'true';
    },

    setConsent: (value = true) => {
        if (value) {
            Cookies.set(COOKIE_CONSENT_KEY, 'true', { expires: 365 });
        } else {
            Cookies.remove(COOKIE_CONSENT_KEY);
        }
    },

    removeConsent: () => {
        Cookies.remove(COOKIE_CONSENT_KEY);
    },

    // Add other cookie-related utilities as needed
    getAllCookies: () => {
        return Cookies.get();
    },

    clearAllCookies: () => {
        Object.keys(Cookies.get()).forEach(cookieName => {
            Cookies.remove(cookieName);
        });
    }
};

export default cookieManager;
