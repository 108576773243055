import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getProducts } from '../client/paymentService';
import { Helmet } from 'react-helmet-async';

const Pricing = () => {
  const navigate = useNavigate();
  const [selectedInterval, setSelectedInterval] = useState('month');
  const [products, setProducts] = useState({ subscription_products: [], one_time_products: [] });
  const [error, setError] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await getProducts();
        setProducts({
          subscription_products: data?.filter(p => p.is_subscription) || [],
          one_time_products: data?.filter(p => !p.is_subscription) || []
        });
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to load product information. Please try again later.');
      }
    };
    fetchProducts();
  }, []);

  const handleChooseProduct = (selectedProduct, isSubscription = true) => {
    if (selectedProduct.prices?.length === 0) {
      if (user?.active_subscription === null) {
        return; // User is already on free plan
      }
      // Maybe show a modal to confirm downgrading to free plan
      return;
    }

    const paymentData = {
      selectedProduct,
      stripePriceId: isSubscription
        ? selectedProduct.currentPrice?.id
        : selectedProduct.prices[0]?.id
    };

    if (user) {
      navigate('/payment', { state: { paymentData } });
    } else {
      localStorage.setItem('paymentData', JSON.stringify(paymentData));
      navigate('/login', { state: { from: '/payment' } });
    }
  };

  // Helper function to get unique intervals from all products
  const getUniqueIntervals = (products) => {
    const intervals = new Set();
    products.forEach(product => {
      if (product.prices && Array.isArray(product.prices)) {
        product.prices.forEach(price => {
          if (price?.recurring?.interval) {  // Updated path to interval
            intervals.add(price.recurring.interval);
          }
        });
      }
    });
    return Array.from(intervals).sort((a, b) => {
      const order = { day: 1, week: 2, month: 3, year: 4 };
      return order[a] - order[b];
    });
  };

  // Helper function to get the current price based on selected interval
  const getCurrentPrice = (prices, interval) => {
    return prices.find(price => price.recurring?.interval === interval);  // Updated path to interval
  };

  // Helper function to format interval display
  const formatInterval = (interval) => {
    return interval.charAt(0).toUpperCase() + interval.slice(1) + 'ly';
  };

  // Sort and filter subscription products
  const filteredSubscriptionProducts = products.subscription_products
    .filter(product =>
      // Include free products or products that have a price matching the selected interval
      product.prices.length === 0 ||
      product.prices.some(price => price.recurring?.interval === selectedInterval)
    )
    .map(product => ({
      ...product,
      currentPrice: {
        ...getCurrentPrice(product.prices, selectedInterval),
        amount_dollars: getCurrentPrice(product.prices, selectedInterval)?.unit_amount ?
          getCurrentPrice(product.prices, selectedInterval).unit_amount / 100 : 0
      }
    }))
    .sort((a, b) => {
      if (a.prices.length === 0) return -1;
      if (b.prices.length === 0) return 1;
      return a.currentPrice.amount_dollars - b.currentPrice.amount_dollars;
    });

  const availableIntervals = getUniqueIntervals(products.subscription_products);

  // Sort one-time products by price
  const sortedOneTimeProducts = [...products.one_time_products]
    .sort((a, b) => a.amount_dollars - b.amount_dollars);

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="alert alert-error">{error}</div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Pricing | {`${process.env.REACT_APP_SITE_NAME}`}</title>
        <meta name="description" content="Choose from our flexible pricing plans. We offer free, monthly, and yearly subscription options to suit your needs." />
        <meta name="keywords" content="pricing plans, subscription, monthly plan, yearly plan, free plan" />
        <link rel="canonical" href={`https://${process.env.REACT_APP_SITE_DOMAIN}/pricing`} />

        {/* Open Graph tags */}
        <meta property="og:title" content={`Pricing | ${process.env.REACT_APP_SITE_NAME}`} />
        <meta property="og:description" content="Choose from our flexible pricing plans. We offer free, monthly, and yearly subscription options to suit your needs." />
        <meta property="og:url" content={`https://${process.env.REACT_APP_SITE_DOMAIN}/pricing`} />
      </Helmet>

      <main className="container mx-auto px-4 py-16">
        {/* Updated hero section */}
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h1 className="text-5xl font-bold mb-4">
            Find Your Perfect Plan
          </h1>
          <p className="text-xl text-base-content/70">
            Whether you're just starting out or scaling up, we have a solution that grows with you
          </p>
        </div>

        {/* Subscription Products with improved section styling */}
        <section
          className="mb-24 rounded-3xl bg-base-200 p-8 md:p-12"
          aria-labelledby="subscription-plans"
        >
          <div className="text-center mb-12">
            <span className="px-4 py-1.5 rounded-full bg-primary/10 text-primary text-sm font-medium mb-4 inline-block">
              SUBSCRIPTION PLANS
            </span>
            <h2 id="subscription-plans" className="text-3xl font-bold mb-4">
              Flexible Plans That Scale With You
            </h2>
            <p className="text-base-content/70 mb-8">
              Choose the perfect billing cycle that works for you
            </p>

            {/* Interval toggle with improved styling */}
            {availableIntervals.length > 0 && (
              <div className="inline-flex rounded-lg bg-base-100 p-2 shadow-sm">
                {availableIntervals.map(interval => (
                  <button
                    key={interval}
                    className={`px-4 py-2 rounded-md transition-all ${
                      selectedInterval === interval
                        ? 'bg-primary text-primary-content shadow-sm'
                        : 'hover:bg-base-200'
                    }`}
                    onClick={() => setSelectedInterval(interval)}
                  >
                    {formatInterval(interval)}
                  </button>
                ))}
              </div>
            )}
          </div>

          <div className="max-w-5xl mx-auto">
            <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {filteredSubscriptionProducts.map((product) => (
                <div
                  key={product.id}
                  className={`card shadow-xl ${
                    product.prices.length === 0 ? 'bg-base-200' : 'bg-primary/10 border-2 border-primary'
                  }`}
                >
                  <div className="card-body">
                    <h2 className="card-title">{product.name}</h2>
                    <p className="text-lg font-semibold mb-4">
                      ${product.prices.length === 0 ? '0' : product.currentPrice.amount_dollars}
                      <span className="text-sm text-base-content/70">
                        /{selectedInterval}
                      </span>
                    </p>
                    <p className="mb-4">{product.description}</p>
                    <div className="card-actions justify-end mt-4">
                      <button
                        className={`btn ${product.prices.length === 0 ? 'btn-secondary' : 'btn-primary'}`}
                        onClick={() => handleChooseProduct({
                          ...product,
                          selectedInterval,
                          stripePriceId: product.currentPrice?.stripe_price_id
                        }, true)}
                      >
                        {product.prices.length === 0 ? 'Start Free' : 'Choose Plan'}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* One-Time Products with improved section styling */}
        {sortedOneTimeProducts.length > 0 && (
          <section
            className="rounded-3xl bg-secondary/5 p-8 md:p-12"
            aria-labelledby="one-time-purchases"
          >
            <div className="text-center mb-12">
              <span className="px-4 py-1.5 rounded-full bg-secondary/10 text-secondary text-sm font-medium mb-4 inline-block">
                ONE-TIME PURCHASES
              </span>
              <h2 id="one-time-purchases" className="text-3xl font-bold mb-4">
                Additional Resources
              </h2>
              <p className="text-base-content/70 mb-8">
                Enhance your experience with these one-time purchases
              </p>
            </div>

            <div className="max-w-5xl mx-auto">
              <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {sortedOneTimeProducts.map((product) => (
                  <div key={product.id} className="card bg-base-100 shadow-xl hover:shadow-2xl transition-shadow">
                    <div className="card-body">
                      <h2 className="card-title">{product.name}</h2>
                      <p className="text-base-content/70">{product.description}</p>
                      <p className="text-2xl font-bold mt-4">
                        ${product.prices.length === 0 ? '0' : (product.prices[0]?.unit_amount / 100).toFixed(2)}
                        <span className="text-base font-normal text-base-content/70">
                          {' '}one-time
                        </span>
                      </p>
                      <div className="card-actions justify-end mt-4">
                        <button
                          className="btn btn-secondary"
                          onClick={() => handleChooseProduct(product, false)}
                        >
                          Purchase Now
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        )}
      </main>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "PriceSpecification",
          "name": "Subscription Plans",
          "offers": products.subscription_products.map(product => ({
            "@type": "Offer",
            "name": product.name,
            "description": product.description,
            "price": product.prices.length === 0 ? "0" :
              (product.prices[0]?.unit_amount / 100).toString(),
            "priceCurrency": "USD",
            "availability": "https://schema.org/InStock"
          }))
        })}
      </script>
    </>
  );
};

export default Pricing;
