import React from 'react';
import { ArrowDownCircleIcon } from '@heroicons/react/24/outline'

// const TEST_DEVICES = {
//     CHROME: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36',
//     SAFARI_IOS: 'Mozilla/5.0 (iPhone; CPU iPhone OS 17_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4 Mobile/15E148 Safari/604.1',
//     SAFARI_MAC: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4 Safari/605.1.15',
//     ANDROID: 'Mozilla/5.0 (Linux; Android 14) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.6312.40 Mobile Safari/537.36'
// };

// const mockUserAgent = (userAgent) => {
//     Object.defineProperty(navigator, 'userAgent', {
//         get: () => userAgent,
//         configurable: true
//     });
// };

// if (process.env.NODE_ENV === 'development') {
//     // Uncomment the device you want to test:
//     // mockUserAgent(TEST_DEVICES.SAFARI_IOS);
//     // mockUserAgent(TEST_DEVICES.SAFARI_MAC);
//     // mockUserAgent(TEST_DEVICES.ANDROID);
// }

const InstallPrompt = () => {
    const [deferredPrompt, setDeferredPrompt] = React.useState(null);
    const [isInstalled, setIsInstalled] = React.useState(false);
    const [showModal, setShowModal] = React.useState({ show: false, isIOS: false, isAndroid: false });

    React.useEffect(() => {
        // Check if already installed as PWA
        if (window.matchMedia('(display-mode: standalone)').matches ||
            window.navigator.standalone === true) {
            setIsInstalled(true);
            return;
        }

        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        window.addEventListener('appinstalled', () => {
            setIsInstalled(true);
            setDeferredPrompt(null);
        });

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    // Don't show anything if already installed
    if (isInstalled) {
        return null;
    }

    // Don't show anything on Chrome if no install prompt is available
    if (!deferredPrompt && /chrome/i.test(navigator.userAgent)) {
        return null;
    }

    const handleInstall = async () => {
        // For Chrome with install prompt
        if (deferredPrompt) {
            deferredPrompt.prompt();
            const { outcome } = await deferredPrompt.userChoice;
            console.log(`User response to the install prompt: ${outcome}`);
            setDeferredPrompt(null);
            return;
        }

        // For Safari or Android
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent) || /android/i.test(navigator.userAgent)) {
            const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
            const isAndroid = /android/i.test(navigator.userAgent);
            setShowModal({ show: true, isIOS, isAndroid });
            return;
        }
    };

    const InstallInstructionsModal = ({ isIOS, isAndroid, onClose }) => {
        return (
            <div className="modal modal-open">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">
                        Install this app on your {isIOS ? 'iOS device' : isAndroid ? 'Android device' : 'Mac'}
                    </h3>
                    <ol className="py-4 space-y-4">
                        {isAndroid ? (
                            <>
                                <li className="flex items-center">
                                    <span className="mr-2">1.</span>
                                    Tap the menu button in the top right corner
                                </li>
                                <li className="flex items-center">
                                    <span className="mr-2">2.</span>
                                    Tap "Install App" or "Add to Home Screen"
                                </li>
                            </>
                        ) : (
                            <>
                                <li className="flex items-center">
                                    <span className="mr-2">1.</span>
                                    {isIOS ? 'Tap' : 'Click'} the Share button
                                    {isIOS ? 'at the bottom of your screen' : 'in the toolbar'}
                                </li>
                                <li className="flex items-center">
                                    <span className="mr-2">2.</span>
                                    {isIOS ? 'Scroll down and tap' : 'Select'}
                                    "Add to {isIOS ? 'Home Screen' : 'Dock'}"
                                </li>
                                <li className="flex items-center">
                                    <span className="mr-2">3.</span>
                                    {isIOS ? 'Tap' : 'Click'}
                                    "Add" {isIOS ? 'in the top right corner' : 'to confirm'}
                                </li>
                            </>
                        )}
                    </ol>
                    <div className="modal-action">
                        <button className="btn" onClick={onClose}>Close</button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <button
                className="btn btn-ghost btn-sm normal-case text-neutral-content"
                onClick={handleInstall}
            >
                <ArrowDownCircleIcon className="w-5 h-5 mr-2" />
                Install App
            </button>
            {showModal.show && (
                <InstallInstructionsModal
                    isIOS={showModal.isIOS}
                    isAndroid={showModal.isAndroid}
                    onClose={() => setShowModal({ show: false, isIOS: false, isAndroid: false })}
                />
            )}
        </>
    );
};

export default InstallPrompt;
