import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkout } from '../client/paymentService';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { loadStripe } from '@stripe/stripe-js';

const Payment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [paymentData] = useState(location.state?.paymentData);
  const [error, setError] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const [stripePromise, setStripePromise] = useState(null);

  const handleCheckout = useCallback(async () => {
    try {
      if (!location.state?.paymentData) {
        throw new Error('No payment data provided');
      }

      const data = location.state.paymentData;
      const priceId = data.stripePriceId;

      if (!priceId) {
        throw new Error('No price ID provided');
      }

      const checkoutData = await checkout(priceId);

      if (checkoutData.clientSecret && checkoutData.publishableKey) {
        setClientSecret(checkoutData.clientSecret);
        setStripePromise(loadStripe(checkoutData.publishableKey));
      } else {
        throw new Error('Missing client secret or publishable key');
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
      if (error.response?.status === 401) {
        navigate('/login', { state: { from: location } });
      } else {
        setError(error.message || 'An error occurred. Please try again.');
      }
    }
  }, [location, navigate]);

  useEffect(() => {
    if (!user) {
      navigate('/login', { state: { from: '/payment' } });
    } else if (!paymentData) {
      console.log("No payment data, navigating to pricing");
      navigate('/pricing');
    } else {
      console.log("Payment data:", location.state.paymentData);
      handleCheckout();
    }
  }, [user, paymentData, navigate, location.state, handleCheckout]);

  const handlePaymentResult = () => {
    // Redirect to success page - the webhook will handle the actual payment confirmation
    navigate('/payment-success');
  };

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="alert alert-error">{error}</div>
      </div>
    );
  }

  return (
      <>
          <Helmet>
              <title>Payment | {process.env.REACT_APP_SITE_NAME}</title>
              <meta name="description" content="Complete your payment securely for your selected plan or product." />
              <meta name="keywords" content="payment, checkout, secure payment" />
              <link rel="canonical" href={`https://${process.env.REACT_APP_SITE_DOMAIN}/payment`} />

              {/* Open Graph tags */}
              <meta property="og:title" content={`Payment | ${process.env.REACT_APP_SITE_NAME}`} />
              <meta property="og:description" content="Complete your payment securely for your selected plan or product." />
              <meta property="og:url" content={`https://${process.env.REACT_APP_SITE_DOMAIN}/payment`} />
          </Helmet>
          <div className="container mx-auto px-4 py-16">
              <h1 className="text-4xl font-bold text-center mb-12">Complete Your Payment</h1>
              {location.state?.paymentData && (
                  <div className="text-center mb-8">
                      {location.state.paymentData.isSubscription ? (
                          <>
                              <p>You've selected the <strong>{location.state.paymentData.selectedProduct.name}</strong> plan.</p>
                              {/* <p>Price: ${location.state.paymentData.isYearly
                  ? location.state.paymentData.selectedProduct.yearly_price
                  : location.state.paymentData.selectedProduct.monthly_price}/
                  {location.state.paymentData.isYearly ? 'year' : 'month'}</p> */}
                          </>
                      ) : (
                          <>
                              <p>You're purchasing <strong>{location.state.paymentData.selectedProduct.name}</strong></p>
                              {/* <p>Price: ${location.state.paymentData.selectedProduct.price}</p> */}
                          </>
                      )}
                  </div>
              )}
              {clientSecret && (
                  <EmbeddedCheckoutProvider
                      stripe={stripePromise}
                      options={{ clientSecret }}
                  >
                      <EmbeddedCheckout onComplete={handlePaymentResult} />
                  </EmbeddedCheckoutProvider>
              )}
          </div>
      </>
  );
};

export default Payment;
