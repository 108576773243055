import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from './Logo';
import { logout } from '../client/authService';
import { useAuth } from '../contexts/AuthContext';

const TopNavbar = () => {
    const { user, setUser } = useAuth();
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const desktopDropdownRef = useRef(null);
    const mobileDropdownRef = useRef(null);

    const handleLogout = async () => {
        console.log('Logging out');
        try {
            await logout();
            setUser(null);
            navigate('/');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleProfileClick = (e) => {
        console.log('Profile click handler called');
        e.preventDefault();
        e.stopPropagation();
        setIsDropdownOpen(false);
        navigate('/profile');
    };

    const handleLogoutClick = async (e) => {
        console.log('Logout click handler called');
        e.preventDefault();
        e.stopPropagation();
        setIsDropdownOpen(false);
        await handleLogout();
    };

    const handleMobileMenuClick = () => {
        setIsDropdownOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                (desktopDropdownRef.current && !desktopDropdownRef.current.contains(event.target)) &&
                (mobileDropdownRef.current && !mobileDropdownRef.current.contains(event.target))
            ) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="navbar bg-base-100 shadow-sm">
            <div className="navbar-start">
                <div className="dropdown">
                    <label tabIndex={0} className="btn btn-ghost sm:hidden" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
                        </svg>
                    </label>
                    {isDropdownOpen && (
                        <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                            <li><Link to="/pricing" onClick={handleMobileMenuClick}>Pricing</Link></li>
                            {!user && (
                                <>
                                    <li><Link to="/login" onClick={handleMobileMenuClick}>Login</Link></li>
                                    <li><Link to="/signup" onClick={handleMobileMenuClick}>Sign Up</Link></li>
                                </>
                            )}
                        </ul>
                    )}
                </div>
                <Link to="/" className="btn btn-ghost text-xl md:text-2xl font-bold hover:bg-transparent flex items-center gap-2">
                    <Logo />
                    <span>django-shipfast</span>
                </Link>
            </div>

            <div className="navbar-end hidden sm:flex">
                <Link to="/pricing" className="btn btn-ghost normal-case">Pricing</Link>
                <div className="divider divider-horizontal"></div>
                {user ? (
                    <div className="dropdown dropdown-end" ref={desktopDropdownRef}>
                        <label tabIndex={0} className="btn btn-ghost" onClick={toggleDropdown}>
                            Hi, {user.username || user.email}
                        </label>
                        {isDropdownOpen && (
                            <ul tabIndex={0} className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52 text-base-content">
                                {user.membership_name && (
                                    <li className="menu-title">
                                        <span>{user.membership_name} Plan</span>
                                        <span className="text-xs">{user.membership_description}</span>
                                    </li>
                                )}
                                <li>
                                    <button onClick={handleProfileClick}>Profile</button>
                                </li>
                                <li>
                                    <button onClick={handleLogoutClick}>Logout</button>
                                </li>
                            </ul>
                        )}
                    </div>
                ) : (
                    <>
                        <Link to="/login" className="btn btn-ghost">Login</Link>
                        <Link to="/signup" className="btn btn-primary">Sign Up</Link>
                    </>
                )}
            </div>

            {user && (
                <div className="navbar-end sm:hidden">
                    <div className="dropdown dropdown-end" ref={mobileDropdownRef}>
                        <label tabIndex={0} className="btn btn-ghost" onClick={toggleDropdown}>
                            Hi, {user.username || user.email}
                        </label>
                        {isDropdownOpen && (
                            <ul tabIndex={0} className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52 text-base-content">
                                {user.membership_name && (
                                    <li className="menu-title">
                                        <span>{user.membership_name} Plan</span>
                                        <span className="text-xs">{user.membership_description}</span>
                                    </li>
                                )}
                                <li>
                                    <button onClick={handleProfileClick}>Profile</button>
                                </li>
                                <li>
                                    <button onClick={handleLogoutClick}>Logout</button>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TopNavbar;
